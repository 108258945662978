import { render, staticRenderFns } from "./HousingLetterPage.vue?vue&type=template&id=518bdded&"
import script from "./HousingLetterPage.vue?vue&type=script&lang=ts&"
export * from "./HousingLetterPage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/workspace/src/services/webapp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('518bdded')) {
      api.createRecord('518bdded', component.options)
    } else {
      api.reload('518bdded', component.options)
    }
    module.hot.accept("./HousingLetterPage.vue?vue&type=template&id=518bdded&", function () {
      api.rerender('518bdded', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/Letter/HousingLetterPage.vue"
export default component.exports