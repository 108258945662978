
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import LetterContainer from '@/components/Letter/LetterContainerComponent.vue';
import LetterHeader from '@/components/Letter/LetterHeaderComponent.vue';
import LetterFooter from '@/components/Letter/LetterFooterComponent.vue';
import { formatDate } from '@/services/formatService';

@Component<HousingLetterPage>({
  components: { LetterContainer, LetterFooter, LetterHeader },
  filters: {
    formatDate(date: Date) {
      return formatDate(date);
    }
  }
})
export default class HousingLetterPage extends Vue {
  @Prop({ default: () => new Date() })
  dateArriving!: Date;

  @Prop({ default: 'here' })
  fromLocation!: string;

  @Prop({ default: 'there' })
  toLocation!: string;

  @Prop({ default: '2LT Sanders, Edward' })
  candidateName!: string;
}
