var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "letter-container",
    [
      _c("letter-header"),
      _c("div", [
        _c("div", {}, [_vm._v("MEMORANDUM IN LIEU OF ORDERS")]),
        _c("div", { staticClass: "flex mt-4" }, [
          _c("div", { staticClass: "mr-4" }, [_vm._v("FROM:")]),
          _c("div", {}, [
            _c("div", {}, [_vm._v("340 FTG/UFT")]),
            _c("div", {}, [_vm._v("375 J Street West, Ste 1")]),
            _c("div", {}, [_vm._v("JBSA-Randolph TX 78150")]),
          ]),
        ]),
        _c("div", { staticClass: "flex mt-4" }, [
          _vm._v(
            " SUBJECT: Letter in Lieu Of PCS Orders for " +
              _vm._s(_vm.candidateName) +
              ". "
          ),
        ]),
        _c("div", { staticClass: "mt-4" }, [
          _vm._v(
            " 1: This letter serves as official authorizion while member's orders are still processing. Student is scheduled to PCS from "
          ),
          _c("span", { staticClass: "font-bold" }, [
            _vm._v(_vm._s(_vm.fromLocation)),
          ]),
          _vm._v(" to "),
          _c("span", { staticClass: "font-bold" }, [
            _vm._v(_vm._s(_vm.toLocation)),
          ]),
          _vm._v(" and is scheduled to arrive on "),
          _c("span", { staticClass: "font-bold" }, [
            _vm._v(_vm._s(_vm._f("formatDate")(_vm.dateArriving))),
          ]),
          _vm._v(". "),
        ]),
        _c("div", { staticClass: "mt-4" }, [
          _vm._v(
            " 2: A certified copy of " +
              _vm._s(_vm.candidateName) +
              "'s' PCS orders can be provided to your office after final routing. "
          ),
        ]),
        _c("div", { staticClass: "mt-4" }, [
          _vm._v(
            " 3: For questions, please contact us at 210-652-6107, or via email 340FTG.UFT.Workflow@us.af.mil "
          ),
        ]),
      ]),
      _c("letter-footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }